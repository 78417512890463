import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Provider, { Context } from 'store/index.js';
import Keycloak from 'keycloak-js';
import env from "@beam-australia/react-env";

import Header from './containers/header/header.js';
import FormBlock from './components/formBlock/formBlock.js';
import Content from './containers/content/content.js';
import NotFound from './containers/notfound/notfound.js';
import Proposals from './containers/proposals/proposals.js';
import Offer from './containers/offer/offer.js';
import University from './containers/university/university.js';
import Personal from './containers/personal/personal.js';
import Footer from './containers/footer/footer.js';
import SomethingWrong from './components/somethingWrong/somethingWrong.js';
import Chatbot from './components/chatbot/chatbot.js';
import './assets/styles/App.scss';
import Loader from 'components/loader/loader.js';

const App = ({ templateParams }) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    window.keycloak = new Keycloak({
      realm: env('SSO_REALM'),
      url: env('SSO_SERVER'),
      clientId: env('SSO_JS_CLIENT_ID'),
    });
  }, []);

  return (
    <Provider t={t} templateParams={templateParams} language={i18n.language}>
      <Context.Consumer>
        {(context) => {
          const { ready, campaigns, menuStatus, hideChatBot, loader } = context.state;
          const parent = `App-intro ${menuStatus ? 'open' : ''} ${ready ? 'show' : ''}`;

          return (
            <Router>
              <div className={parent}>
                {ready ? (
                  <>
                    <Header />
                    <Switch>
                      <Route
                        exact
                        strict
                        path="/:url*"
                        render={(props) => <Redirect to={`${props.location.pathname}/`} />}
                      />
                      <RouterWithLocale exact path={['/', '/:locale/']} component={Content} />
                      {/* <Route exact path="/byf/" component={Content}/> */}
                      <RouterWithLocale
                        exact
                        path={['/simagochi/', '/:locale/simagochi']}
                        component={Content}
                      />
                      <RouterWithLocale
                        exact
                        path={['/proposals/', '/:locale/proposals/']}
                        component={Proposals}
                      />
                      <RouterWithLocale
                        exact
                        path={['/university/', '/:locale/university/']}
                        component={University}
                      />

                      <RouterWithLocale
                        exact
                        path={['/sign-up/', '/:locale/sign-up']}
                        render={() => <FormBlock form="sign" />}
                      />
                      <RouterWithLocale
                        exact
                        path={['/accept/:slug/', '/:locale/accept/:slug/']}
                        render={() => <FormBlock form="accept" />}
                      />
                      <RouterWithLocale
                        exact
                        path={['/contact-us/', '/:locale/contact-us/']}
                        render={() => <FormBlock form="contact" />}
                      />
                      <RouterWithLocale
                        exact
                        path={['/faq/', '/:locale/faq/']}
                        render={() => <Personal text={context.state.faq} />}
                      />
                      <RouterWithLocale
                        exact
                        path={['/legal/', '/:locale/legal/']}
                        render={() => <Personal text={context.state.legal} />}
                      />
                      <RouterWithLocale
                        exact
                        path={['/profile/', '/:locale/profile/']}
                        render={() => <Personal profile />}
                      />
                      <RouterWithLocale
                        exact
                        path={['/proposals/:slug/', '/:locale/proposals/:slug']}
                        render={(props) => {
                          const slug = props.match.params.slug,
                            offer = campaigns.find((item) => item.slug === slug),
                            newRoute = !offer ? (
                              <NotFound />
                            ) : (
                              <Offer history={props.history} item={offer} />
                            );
                          return newRoute;
                        }}
                      />
                      <Route path="*" render={() => <NotFound />} />
                    </Switch>
                    <Footer />
                    {!hideChatBot && <Chatbot />}
                    {loader && <Loader />}
                  </>
                ) : (
                  <SomethingWrong />
                )}
              </div>
            </Router>
          );
        }}
      </Context.Consumer>
    </Provider>
  );
};
export default App;

const RouterWithLocale = (props) => {
  const { i18n } = useTranslation();
  const {
    location,
    computedMatch: { params },
  } = props;

  if (!['en', 'uk'].includes(params.locale)) {
    i18n.changeLanguage('uk');

    return <Redirect to={`/uk${location.pathname}`} />;
  }

  return <Route {...props} />;
};
