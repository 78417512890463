import React, { useContext } from 'react';
import { Context } from 'store';
import { useTranslation } from 'react-i18next';

export const AuthMessage = () => {
  const { t } = useTranslation();

  const context = useContext(Context);
  const { keycloakLogout, state } = context;
  const {
    keycloakParams: { auth, msisdn },
  } = state;

  if (auth && msisdn) {
    return (
      <>
        <p className="form-msisdn">
          {t('authMsisdn')}
          {msisdn}
        </p>
        <p className="button-yellow" onClick={() => keycloakLogout()}>
          {t('changeNumber')}
        </p>
      </>
    );
  }
  return null;
};

const AuthButton = () => {
  const { t } = useTranslation();

  const { clickLoginBth } = useContext(Context);

  return (
    <p className="button-yellow" onClick={clickLoginBth}>
      {t('login')}
    </p>
  );
};

export default AuthButton;
