const initSSO = (keycloakUpdate, keycloakCallback, thirdCookies) => {
  const keycloak = window.keycloak;
  const token = localStorage.getItem('kc_token');
  const refreshToken = localStorage.getItem('kc_refreshToken');
  const isFirefox = navigator.userAgent.toLowerCase().includes('firefox');

  const keycloakInitOptions = {
    onLoad: 'check-sso',
    promiseType: 'native',
    checkLoginIframe: !isFirefox,
    silentCheckSsoFallback: !isFirefox,
    silentCheckSsoRedirectUri: window.location.origin + '/check-sso.html',
  };

  const onKeycloakReady = () => {
    if (!keycloak.authenticated) {
      removeKeycloakTokens();
    }

    keycloakUpdate(keycloak);
    keycloakCallback(keycloak.authenticated);
  };

  const onKeycloakError = () => {
    removeKeycloakTokens();
  };

  const setKeycloakTokens = (token, refreshToken) => {
    localStorage.setItem('kc_token', token);
    localStorage.setItem('kc_refreshToken', refreshToken);
    keycloakUpdate(keycloak);
  };

  const removeKeycloakTokens = () => {
    localStorage.removeItem('kc_token');
    localStorage.removeItem('kc_refreshToken');
    keycloakUpdate(keycloak);
  };

  try {
    if (token && refreshToken && thirdCookies) {
      const keycloakInitOptionsWithTokens = keycloakInitOptions;
      keycloakInitOptionsWithTokens.token = token;
      keycloakInitOptionsWithTokens.refreshToken = refreshToken;
      keycloak.init(keycloakInitOptionsWithTokens).then(onKeycloakReady);
    } else {
      keycloak.init(keycloakInitOptions).then(onKeycloakReady);
    }
  } catch (e) {
    console.log(e);
    //keycloakInitOptions.checkLoginIframe = false;
    keycloak.init(keycloakInitOptions).then(onKeycloakReady);
  }

  keycloak.onAuthSuccess = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'user-id-available',
      userId: keycloak.tokenParsed.msisdn,
    });
    removeKeycloakTokens();
    setKeycloakTokens(keycloak.token, keycloak.refreshToken);
  };

  keycloak.onAuthRefreshSuccess = () => {
    setKeycloakTokens(keycloak.token, keycloak.refreshToken);
  };

  keycloak.onAuthLogout = removeKeycloakTokens;
  keycloak.onAuthRefreshError = onKeycloakError;
  keycloak.onAuthError = onKeycloakError;
};

export default initSSO;
