import React from 'react';
import Social from '../../components/social/social.js';
import Menu from '../../components/menu/menu.js';
import Icon from '../../components/icon/icon.js';

const Footer = () => (
  <footer className="footer">
    <div className="menu">
      <div className="menu-in">
        <Menu />
        <a
          href="https://www.lifecell.ua/uk/"
          target="_blank"
          rel="noopener noreferrer"
          className="lifecell_logo"
        >
          <Icon iconClass="footer-logo" icon="lifecell_logo" width="67" height="22" />
        </a>
      </div>
    </div>
    <Social />
  </footer>
);
export default Footer;
