import axios from 'axios';
import i18n from 'i18n/config';

const instance = axios.create();

instance.interceptors.request.use(
  (config) => {
    const currentLang = i18n.languages.includes(i18n.language) ? i18n.language  : 'uk';
    const token = localStorage.getItem('kc_token');

    config.headers['X-Lang'] = currentLang;

    if (token) {
      config.headers['Authorization'] = `Token ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default instance;
