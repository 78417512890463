import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import env from '@beam-australia/react-env';

import Modal from 'components/modal/Modal';
import Checkbox from 'components/checkbox/Checkbox';
import Link from 'components/link/link';

import { Context } from 'store';
import axios from 'lib/axios';

import cancelCampusImg from '../../assets/images/cancel-campus.svg';
import smileImg from '../../assets/images/smile.svg';

const DeleteIdCard = () => {
  const { t } = useTranslation();
  const context = useContext(Context);
  const { getuser } = context;

  const [isChecked, setIsChecked] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);

  const toHome = () => {
    context.clearState();
    context.updateState('menuStatus', false);
    window.scrollTo(0, 0);
  };

  const handledeleteIdCard = async () => {
    await axios({
      method: 'delete',
      url: `${env('API')}user/`,
    });
    setIsDeleted(true);
  };

  const handleCloseModal = async () => {
    if (!isDeleted) return;

    toHome();
    await getuser('update');
  }

  const renderContent = () => {
    if (isDeleted) {
      return (
        <div className='cancel-campus'>
          <img src={smileImg} alt="smile" />
          <h2 className="cancel-campus-title">{t('cancelMembership')}</h2>
          <p className="cancel-campus-text">{t('cancelMembershipSuccess')}</p>
          <Link onClick={handleCloseModal} className="logo" to="/">
            <div className="cancel-campus-button-wrapper-success">
              <button className="button button-full button-secondary">{t('homeCampus')}</button>
            </div>
          </Link>
        </div>
      );
    }

    return (
      <div className='cancel-campus'>
        <img src={cancelCampusImg} alt="cancel-campus" className="cancel-campus-img" />
        <h2 className="cancel-campus-title">{t('cancelMembership')}</h2>
        <p className="cancel-campus-text">{t('cancelMembershipText1')}</p>
        <p className="cancel-campus-text warning">{t('cancelMembershipText2')}</p>
        <Checkbox label={t('agreement')} checked={isChecked} onChange={setIsChecked} />
        <div className="cancel-campus-button-wrapper">
          <button className="button button-full" disabled={!isChecked} onClick={handledeleteIdCard}>
            {t('deleteIdCard')}
          </button>
        </div>
      </div>
    );
  };

  const renderTrigger = () => (
    <div className="delete-card-button-wrapper">
      <button className="delete-card-button">{t('deleteIdCard')}</button>
    </div>
  );

  return (
    <Modal
      trigger={renderTrigger()}
      onClose={handleCloseModal}
    >
      {renderContent()}
    </Modal>
  );
};

export default DeleteIdCard;
