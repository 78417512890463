const gaTracker = (type, params) => {
  if (window.ga && window.ga.loaded) {
    const trackerName = window.ga.getAll()[0].get('name');
    window.ga(`${trackerName}.send`, type, params);
  }
};

export const trackGAPageview = (url) => {
  gaTracker('pageview', url);
};

export const trackGAEvent = ({ action, category, label }) => {
  gaTracker('event', {
    eventCategory: category,
    eventAction: action,
    eventLabel: label,
    transport: 'beacon',
  });
};
