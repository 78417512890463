import React from 'react';
import Main from '../../containers/main/main.js';
import { Cases } from '../../containers/cases/cases.js';
import SimpleSlider from '../../containers/slider/slider.js';
/* import Byfriend from '../../containers/byfriend/byfriend.js'; */
import Events from '../../containers/events/events.js';
import University from '../../containers/university/university.js';

const Content = () => (
  <div>
    <Main />
    <Cases />
    <SimpleSlider />
    {/* <Byfriend /> */}
    <Events />
    <University main />
  </div>
);
export default Content;
