import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import ProposalItem from '../../components/proposal_item/proposal_item.js';
import Link from '../../components/link/link';
import mnp_img from '../../assets/images/mnp.jpg';
import webshop_img from '../../assets/images/webshop.png';
import { Context } from 'store';

const FormSuccess = () => {
  const { t } = useTranslation();

  const context = useContext(Context);
  const { customer } = context.state;

  const omo_campaigns = [
    {
      id: 1,
      code: 'lifecell_mnp',
      name: t('toMnp'),
      link: 'https://mnp.lifecell.ua/uk/',
      img_type: 'static',
      image: mnp_img,
    },
    {
      id: 2,
      code: 'lifecell_shop',
      link: 'https://shop.lifecell.ua/uk/',
      name: t('toLifecell'),
      img_type: 'static',
      image: webshop_img,
    },
  ];

  return (
    <div className="block-items success-page">
      {customer.is_omo ? (
        <>
          {omo_campaigns?.map((item) => (
            <ProposalItem key={item.id} item={item} />
          ))}
          <p className="block-items-offer">
            <Link className="button-yellow" to="/proposals/">
              {t('goToProposals')}
            </Link>
          </p>
        </>
      ) : (
        <>
          <div className="offer-buttons">
            <Link className="button-yellow" to="/proposals/">
              {t('goToProposals')}
            </Link>
          </div>
          <Link className="full-conditions" to="/legal/">
            {t('fullConditions')}
          </Link>
        </>
      )}
    </div>
  );
};

export default FormSuccess;
