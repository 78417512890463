import React, { useContext } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import ProposalItem from '../../components/proposal_item/proposal_item';
import GoBack from '../../components/goBack/goBack';
import Link from '../../components/link/link';

import { Context } from 'store';

const Proposals = () => {
  const { t } = useTranslation();

  const context = useContext(Context);
  const { proposalsList } = context.state;

  window.scrollTo({ top: 0 });

  return (
    <div className="proposals">
      <div className="section-in">
        <GoBack />
        <h4 className="title">{t('proposalsTitle')}</h4>

        <p className="description">
          <Trans i18nKey="proposalsDescription" components={[<br key="br" />, <Link to="/sign-up/" key="link" />]} />
        </p>
        <div className="block-items">
          {proposalsList.map((item) => (
            <ProposalItem key={item.slug} item={item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Proposals;
