import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

const ModalContent = ({ closeModal, children }) => {
  return ReactDOM.createPortal(
    <div className="modal">
      <div className="modal-content">
        <span className="modal-close" onClick={closeModal} />
        {children}
      </div>
      <div className="modal-overlay" onClick={closeModal} />
    </div>,
    document.getElementById('modal'),
  );
};

const Modal = ({ trigger, onClose, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      document.documentElement.style.overflow = 'hidden';

      return;
    }

    document.documentElement.style.overflow = '';
  }, [isOpen]);

  return (
    <>
      <span className="modal-trigger" onClick={openModal}>
        {trigger}
      </span>

      {isOpen && <ModalContent closeModal={closeModal}>{children}</ModalContent>}
    </>
  );
};

export default Modal;
