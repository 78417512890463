import React, { useContext } from 'react';
import Slider from 'react-slick';
import ProposalItem from '../../components/proposal_item/proposal_item.js';
import { Context } from 'store';

const SimpleSlider = () => {
  const context = useContext(Context);
  const { proposalsList } = context.state;

  const settings = {
    dots: true,
    speed: 500,
    autoplay: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    swipeToSlide: true,
    pauseOnHover: true,
    autoplaySpeed: 3000,
    pauseOnDotsHover: true,
    className: 'block-items',
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 570,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const proposals = proposalsList.map((item) => <ProposalItem key={item.slug} item={item} />);

  return (
    <section className="slider" id="slider">
      <div className="slider-wrapper">
        {proposalsList.length > 4 ? (
          <Slider {...settings}>{proposals}</Slider>
        ) : (
          <div className="block-items">{proposals}</div>
        )}
      </div>
    </section>
  );
};

export default SimpleSlider;
