import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Context } from 'store';
import Link from '../../components/link/link';
import classNames from 'classnames';

import { trackGAEvent } from '../../lib/analytics';

const EventBlock = ({ slug, image, name, event }) => {
  const { t } = useTranslation();

  const context = useContext(Context);
  const { event_align, event_short_description } = event;

  const proposalClick = (slug) => {
    context.clearState();
    trackGAEvent({ category: 'button', action: 'click', label: `open ${slug} proposal` });
  };

  const caseClass = classNames('case-item', slug, { reversed: event_align > 0 });

  return (
    <div className={caseClass}>
      <div className="case-text">
        <h2>{name}</h2>
        <p dangerouslySetInnerHTML={{ __html: event_short_description }}></p>
        <Link className="case-link" to={`/proposals/${slug}/`} onClick={() => proposalClick(slug)}>
          {t('details')}
        </Link>
      </div>
      <div className="case-image">
        <img src={image} alt={name} />
      </div>
    </div>
  );
};

const Events = () => {
  const context = useContext(Context),
    { proposalEvents } = context.state;
  return (
    <section className="case event">
      {proposalEvents.map((item) => (
        <EventBlock {...item} key={item.slug} />
      ))}
    </section>
  );
};

export default Events;
