import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Link = (props) => {
  const { i18n } = useTranslation();

  const to = `/${i18n.language}${props.to}`;

  return <RouterLink {...props} to={to} />;
};

export default Link;
