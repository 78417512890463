import React, { useContext } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import Link from '../../components/link/link';
import Icon from '../../components/icon/icon.js';
import number from '../../assets/images/notfound-image404.png';
import astronaut from '../../assets/images/astronaut.png';
import { Context } from 'store';

const NotFound = () => {
  const { t } = useTranslation();

  const context = useContext(Context);
  return (
    <div className="notfound">
      <div className="section-in">
        <Link to="/" onClick={context.clearState}>
          <div className="back">
            <Icon icon="back" />
            <p>{t('home')}</p>
          </div>
        </Link>
        <div className="text">
          <h4>{t('oops')}</h4>
          <p>
            <Trans i18nKey="notFound" components={[<br key="br" />]} />
          </p>
        </div>
      </div>
      <img src={number} className="number" alt="number" />
      <img src={astronaut} className="astronaut" alt="astronaut" />
      <div className="fog"></div>
    </div>
  );
};
export default NotFound;
