import { useEffect, useContext } from 'react';
import env from '@beam-australia/react-env';
import { useTranslation } from 'react-i18next';

import { Context } from '../../store';
import { loadExternalScript } from '../../lib/utils';

const containerId = 'bp-web-widget';

const Chatbot = () => {
  const { device } = useContext(Context);
  const { t, i18n } = useTranslation();

  const initChatbot = () => {
    window.botpressWebChat.init({
      host: env('CHATBOT_HOST'),
      botId: 'campus',
      userLocale: i18n.language || 'uk',
      timeoutShowHalfMan: null,
      helpBotTimeout: 0,
      popupGreeting: '',
      isMobile: device.isMobile,
      showChannelViewAsDefault: device.isMobile,
      chatIconTooltipText: t('chatbot.tooltipTitle'),
      popupText: t('chatbot.popupDescription'),
      popupButtonText: t('chatbot.popupButton'),
      fbLink: null,
      tgLink: env('CHATBOT_TG_LINK'),
      fullScreenLink: env('CHATBOT_FULLSCREEN_LINK'),
    });
  };

  useEffect(() => {
    const prevComponent = document.getElementById(containerId);

    if (prevComponent) {
      prevComponent.remove();
    }

    loadExternalScript({
      id: 'botpress-web-chat',
      src: env('CHATBOT_URL'),
      onLoad: initChatbot,
    });
  }, [i18n.language]);

  return null;
};

export default Chatbot;
