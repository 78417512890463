import React, { useContext } from 'react';
import { Context } from 'store';
import { useTranslation, Trans } from 'react-i18next';

import AuthButton, { AuthMessage } from '../../components/authButton/authButton.js';
import GoBack from '../../components/goBack/goBack.js';
import Loader from '../../components/loader/loader';
import Link from '../../components/link/link';

const OrderBlock = ({ context: { state, getOffer }, item }) => {
  const { t } = useTranslation();

  const context = useContext(Context);
  const { isCampus, isStudent } = state;
  const {
    slug,
    button_link: link,
    button_title: title,
    is_lifecell_service: service,
    allow_non_campus: allUsers,
  } = item;

  const goToRegistration = () => {
    context.changeFormStatus('form');
    context.updateState('redirectAfterRegistration', window.location.href);
  }

  return (
    <div className="offer-order">
      <AuthMessage />
      {link && title && ((isCampus && isStudent) || allUsers) ? (
        <a href={link} className="offer-buy" target="_blank" rel="noopener noreferrer">
          {title}
        </a>
      ) : !isCampus ? (
        <p>
          <Trans
            i18nKey="onlyCampus"
            components={[
              <Link to="/sign-up/" onClick={goToRegistration} key="a" />,
            ]}
          />
        </p>
      ) : !isStudent ? (
        <p>{t('form.error.expiredDocument')}</p>
      ) : (
        <p className="offer-buy" onClick={() => getOffer(service, slug)}>
          {t('getOffer')}
        </p>
      )}
    </div>
  );
};

const DiscountBlock = ({ context }) => {
  const { t } = useTranslation();

  const { orderService, cancelDiscount } = context;
  const { discountText, discountButtons, activeOffer } = context.state;

  return (
    <>
      <p className="discount-description">{discountText}</p>
      <div className="offer-buttons">
        <p
          className="offer-buy"
          onClick={() =>
            orderService(activeOffer['service'], activeOffer['id'], activeOffer['action'])
          }
        >
          {t('getOffer')}
        </p>
        {discountButtons === 'order_cancel' && (
          <p className="offer-buy" onClick={() => cancelDiscount()}>
            {t('noThanks')}
          </p>
        )}
      </div>
    </>
  );
};

const Offer = ({ item, history }) => {
  const { t } = useTranslation();

  const context = useContext(Context);
  const { state, getOffer } = context;
  const { activeOffer, serviceOrderStatus, discountButtons, keycloakParams } = state;
  const { image, name, type, is_lifecell_service, slug, description } = item;
  const { auth } = keycloakParams;

  return (
    <div className="faq">
      <div className="section-in">
        <GoBack />
        <div className="offer">
          <img className="offer-img" src={image} alt={name} />
          <div className="offer-container">
            <h1 className="offer-title">{name}</h1>
            <div
              className="offer-description"
              dangerouslySetInnerHTML={{ __html: description }}
            ></div>
            {type === 'welcome_gift' && (
              <div className="offer-description">
                <p
                  className="disable-discount"
                  onClick={() => getOffer(is_lifecell_service, slug, 'disable')}
                >
                  {t('disableDiscount')}
                </p>
              </div>
            )}
            {!auth ? (
              <AuthButton />
            ) : serviceOrderStatus ? (
              <p
                className="service-status"
                dangerouslySetInnerHTML={{ __html: serviceOrderStatus }}
              ></p>
            ) : !activeOffer ? (
              <OrderBlock context={context} item={item} />
            ) : discountButtons ? (
              <DiscountBlock context={context} />
            ) : (
              <div className="loader-container">
                <Loader />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Offer;
