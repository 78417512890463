import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Link from '../../components/link/link';
import { trackGAEvent } from '../../lib/analytics';
import { Context } from 'store';

const ProposalItem = ({ item }) => {
  const { t } = useTranslation();

  const context = useContext(Context);
  const { slug, image, name, link, link_type } = item;

  const proposalClick = (slug) => {
    context.clearState();
    window.scrollTo({ top: 0 });
    trackGAEvent({ category: 'button', action: 'click', label: `open ${slug} proposal` });
  };

  return (
    <div className="item" key={slug}>
      <div className="item-top">
        <img src={image} alt={name} />
      </div>
      <div className="item-bottom">
        <p className="item-text">{name}</p>
        {link ? (
          <a
            className="item-link"
            target="_blank"
            rel="noopener noreferrer"
            href={link}
            onClick={() => proposalClick(slug)}
          >
            {link_type ? t('buy') : t('details')}
          </a>
        ) : (
          <Link
            className="item-link"
            to={`/proposals/${slug}/`}
            onClick={() => proposalClick(slug)}
          >
            {t('details')}
          </Link>
        )}
      </div>
    </div>
  );
};

export default ProposalItem;
