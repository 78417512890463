import React from 'react';
import Detail from '../../components/detail/detail.js';
import Profile from '../../components/profile/profile.js';
import GoBack from '../../components/goBack/goBack.js';

const List = ({ text }) => (
  <div className="detail-items">
    {text.map((_, index) => (
      <Detail detail={text[index]} key={index} />
    ))}
  </div>
);

const Personal = ({ text, profile }) => {
  if (!profile) window.scrollTo({ top: 0 });

  return (
    <div className="personal">
      <div className="section-in">
        <GoBack />
        {profile && <Profile />}
        {text && <List text={text} />}
      </div>
    </div>
  );
};

export default Personal;
