import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Icon from '../../components/icon/icon.js';
import Link from '../../components/link/link';
import { Context } from 'store';

const GoBack = ({ type }) => {
  const { t } = useTranslation();

  const context = useContext(Context);
  const acceptPage = context.state.formPosition === 'accept';
  const colorClass = classNames('back', type ? 'white' : 'black');

  if (acceptPage) {
    return (
      <Link to="/" onClick={context.clearState}>
        <div className="back white">
          <Icon icon="back" />
          <p>{t('home')}</p>
        </div>
      </Link>
    );
  }

  return (
    <div className={colorClass} onClick={context.goBack}>
      <Icon icon="back" />
      <p>{t('back')}</p>
    </div>
  );
};

export default GoBack;
