import React from 'react';
import Icon from '../../components/icon/icon.js';

const Text = ({ text, line }) => {
  return (
    <div className="text-group">
      {text.map((item, index) => (
        <p key={index}>{item}</p>
      ))}
      <div className="lines">
        {line.map((item, index) => (
          <Icon key={index} icon={item} />
        ))}
      </div>
    </div>
  );
};
export default Text;
