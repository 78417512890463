import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import Link from '../../components/link/link';
import { Context } from 'store';

const Menu = () => {
  const { t } = useTranslation();

  const context = useContext(Context);

  return (
    <div className="menu-items">
      <Link onClick={context.clearState} to="/contact-us/">
        {t('contactUs')}
      </Link>
      <Link onClick={context.clearState} to="/faq/">
        {t('faq')}
      </Link>
      <Link onClick={context.clearState} to="/legal/">
        {t('policy')}
      </Link>
    </div>
  );
};

export default Menu;
