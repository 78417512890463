import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import classNames from 'classnames';

import { languages } from 'i18n/config';

const LanguageSelector = ({ history, className }) => {
  const { i18n } = useTranslation();

  const changeLanguage = (langCode) => {
    if (i18n.language === langCode) return;

    history.push(history.location.pathname.replace(i18n.language, langCode));
    i18n.changeLanguage(langCode);
  };

  return (
    <div className={classNames('change-language', className)}>
      {languages.map((lang, i) => (
        <Fragment key={lang}>
          <span
            className={classNames('change-language-language', {
              'language-active': i18n.language === lang,
            })}
            onClick={() => changeLanguage(lang)}
          >
            {lang}
          </span>

          {languages.at(i + 1) && <span className="change-language-language">/</span>}
        </Fragment>
      ))}
    </div>
  );
};

export default withRouter(LanguageSelector);
