import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import Icon from '../../components/icon/icon';
import Link from '../../components/link/link';
import { Context } from 'store';

const AuthHeader = () => {
  const { t } = useTranslation();

  const { clickLoginBth, clearState, state, logout } = useContext(Context);
  const { isCampus } = state;
  const { auth } = state.keycloakParams;

  const btnClick = () => {
    clearState();
    window.scrollTo({ top: 0 });
  };

  return (
    <>
      <div className="header-auth-icon">
        {auth && state.customer.first_name ? (
          <div className="user-icon">
            <span>{state.customer.first_name.charAt(0)}</span>
          </div>
        ) : (
          <Icon icon="user" width="18" height="18" iconClass="icon-user" />
        )}
        <Icon icon="arrow-small" width="6" height="3" iconClass="arrow-small" />
      </div>
      <div className="header-auth-buttons">
        {isCampus ? (
          <Link to="/profile/" onClick={() => btnClick()} className="header-auth-item">
            <Icon icon="user" width="18" height="18" iconClass="icon-user" />
            <p className="header-auth-title">{t('personalData')}</p>
          </Link>
        ) : (
          <Link to="/sign-up/" onClick={() => btnClick()} className="header-auth-item">
            <Icon icon="user" width="18" height="18" iconClass="icon-user" />
            <p className="header-auth-title">{t('register')}</p>
          </Link>
        )}

        {auth ? (
          <div className="header-auth-item" onClick={logout}>
            <Icon icon="logout" width="18" height="18" iconClass="icon-auth" />
            <p className="header-auth-title">{t('logout')}</p>
          </div>
        ) : (
          <div className="header-auth-item" onClick={clickLoginBth}>
            <Icon icon="login" width="18" height="18" iconClass="icon-auth" />
            <p className="header-auth-title">{t('login')}</p>
          </div>
        )}
      </div>
    </>
  );
};

export default AuthHeader;
