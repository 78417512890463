export const loadExternalScript = ({ id, src, onLoad }) => {
  if (!document.querySelector(`#${id}`)) {
    const script = document.createElement('script');
    script.id = id;
    script.src = src;
    script.async = true;
    script.onload = onLoad;
    document.body.appendChild(script);
  } else {
    onLoad();
  }
};
