import React, { useContext } from 'react';
import { Context } from 'store';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Link from '../../components/link/link';
import Block from '../../components/block/block.js';
import GoBack from '../../components/goBack/goBack.js';
import ProposalItem from '../../components/proposal_item/proposal_item.js';

import university1 from '../../assets/images/university1.jpg';
import university2 from '../../assets/images/university2.jpg';

const Proposals = () => {
  const context = useContext(Context),
    { itProposalsList } = context.state;

  if (itProposalsList.length) {
    return (
      <div className="block-items">
        {itProposalsList.map((item) => (
          <ProposalItem key={item.slug} item={item} />
        ))}
      </div>
    );
  }
  return null;
};

const University = ({ main }) => {
  const { t } = useTranslation();

  const image = main ? university2 : university1;
  const title = main ? t('universityTitle1') : t('universityTitle2');
  const description = main ? t('universityDescription1') : t('universityDescription2');

  const universityClass = classNames('university', { 'main-page': main });

  if (!main) {
    window.scrollTo({ top: 0 });
  }

  return (
    <section className={universityClass}>
      <div className="section-in">
        {!main && <GoBack type="white" />}

        <h2 className="title">{title}</h2>
        <p className="description" dangerouslySetInnerHTML={{ __html: description }}></p>

        {!main && <Proposals />}

        <div className="image">
          <img src={image} alt="university" />
          {!main && (
            <Block
              classname="university"
              text={t('universityDepartament', { returnObjects: true })}
            />
          )}
        </div>

        {main && (
          <Link to="/university/" className="link">
            <p>{t('opportunities')}</p>
          </Link>
        )}
      </div>
    </section>
  );
};
export default University;
