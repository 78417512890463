import React, { useContext } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import Loader from '../../components/loader/loader';
import FormData from '../../components/formData/formData.js';
import AuthButton from '../../components/authButton/authButton.js';
import Link from '../../components/link/link';

import { Context } from 'store';
import DeleteIdCard from 'components/deleteIdCard/DeleteIdCard';

const ProfileContainer = () => {
  const { t } = useTranslation();

  const context = useContext(Context);
  const { state, updateState, clearState, sendData, formatMsisdn, logout } = context;
  const { keycloakParams, formType, formStatus, isCampus, formLoader, generalError, transferData } =
    state;
  const { auth } = keycloakParams;

  if (formType !== 'profile' && formType !== 'line') {
    updateState('formType', 'profile');
  }

  if (auth) {
    const numberState = transferData && transferData.msisdn;
    const numberStorage =
      keycloakParams.msisdn && formatMsisdn(keycloakParams.msisdn).replace(/\s/g, '');

    if (transferData && numberState === numberStorage) {
      if (formStatus === 'error' && generalError) {
        return (
          <p className="error">
            {generalError === t('generalError') ? (
              <Trans
                i18nKey="generalError"
                components={[<span className="title" key="span" />, <br key="br" />]}
              />
            ) : (
              generalError
            )}
          </p>
        );
      }

      return (
        <>
          <p className="profile-description">
            <Trans
              i18nKey="textConfirm"
              components={[<br key="br" />, <span className="title" key="span" />]}
            />
          </p>
          <p className="button-yellow" onClick={() => sendData(transferData)}>
            {t('confirm')}
          </p>
          <p className="button-yellow" onClick={logout}>
            {t('noThanks')}
          </p>
          {formLoader && <Loader />}
        </>
      );
    }

    if (isCampus) {
      return <FormData context={context} />;
    }

    return (
      <>
        <p className="profile-description">
          <span className="title">{t('regToContinue')}</span>
        </p>
        <Link to="/sign-up/">
          <p className="button-yellow" onClick={clearState}>
            {t('campusRegistration')}
          </p>
        </Link>
      </>
    );
  }
  return (
    <>
      <p className="profile-description">
        {transferData ? (
          <Trans
            i18nKey="textTransfer"
            components={[<br key="br" />, <span className="title" key="span" />]}
          />
        ) : (
          <span class="title">{t('authToContinue')}</span>
        )}
      </p>
      <AuthButton />
    </>
  );
};

const Profile = () => {
  const { t } = useTranslation();
  const { state } = useContext(Context);

  return (
    <>
      <div className="profile-container">
        <h1 className="profile-title">{t('personalData')}</h1>
        <ProfileContainer />
      </div>
      {state.isCampus && <DeleteIdCard />}
    </>
  );
};

export default Profile;
