import React from 'react';
import { useTranslation } from 'react-i18next';

import Icon from '../../components/icon/icon.js';

const Social = () => {
  const { t } = useTranslation();
  const social = [
    {
      icon: 'telegram',
      href: 'https://t.me/lifecell_campus/',
    },
    {
      icon: 'my-lifecell',
      href: 'https://www.lifecell.ua/uk/mobilnij-internet/dodatkovi-poslugi/dodatok-miy-lifecell/',
    },
  ];

  return (
    <>
      <div className="social">
        {social.map(({ href, icon }) => (
          <a href={href} key={icon} rel="noopener noreferrer" target="_blank">
            <Icon icon={icon} width="25" height="25" />
          </a>
        ))}
      </div>
      <div className="law-links">
        <a href="https://registry.edbo.gov.ua/" rel="noopener noreferrer" target="_blank">
          {t('activityRegistry')}
        </a>
        <a href="https://info.edbo.gov.ua/edu-documents/" rel="noopener noreferrer" target="_blank">
          {t('documentRegistry')}
        </a>
      </div>
    </>
  );
};

export default Social;
