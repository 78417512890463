import React from 'react';

const Loader = () => (
  <div className="spinner">
    <div className="spinner-in">
      <div className="spinner-item"></div>
      <div className="spinner-item"></div>
      <div className="spinner-item"></div>
    </div>
  </div>
);

export default Loader;
