import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import Icon from '../../components/icon/icon';
import Loader from '../../components/loader/loader';
import { Context } from 'store';

const SomethingWrong = () => {
  const { t } = useTranslation();

  const context = useContext(Context);
  const { ready, error } = context.state;

  return (
    <>
      {!ready &&
        (error ? (
          <div className="logo-error">
            <Icon icon="campus-logo2" width="109" height="29" />
            <div className="main-text">
              {error === 'manyRequests' ? (
                <p>
                  {t('form.error.manyRequests1')}
                  <br />
                  {t('form.error.manyRequests2')}
                </p>
              ) : (
                <p>
                  {t('form.error.somethingWentWrong')}
                  <br />
                  {t('form.error.tryAgain')}
                </p>
              )}
            </div>
          </div>
        ) : (
          <Loader />
        ))}
    </>
  );
};

export default SomethingWrong;
