import React from 'react';
import classNames from 'classnames';

const Checkbox = ({ label, checked, onChange }) => {
  const handleChange = (e) => {
    onChange(e.target.checked);
  };

  return (
    <label className={classNames('checkbox-label', { 'checked': checked })}>
      <input onChange={handleChange} type="checkbox" checked={checked} />
      <span>{label}</span>
    </label>
  );
};

export default Checkbox;
