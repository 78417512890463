import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const namespaceName = 'common';
export const languages = ['uk', 'en'];

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'uk',
    resources: {
      en: {
        [namespaceName]: require(`./locales/en/${namespaceName}.json`),
      },
      uk: {
        [namespaceName]: require(`./locales/uk/${namespaceName}.json`),
      },
    },
    ns: namespaceName,
    detection: {
      order: ['path'],
      lookupFromPathIndex: 0,
    },
  });

i18n.languages = languages;

export default i18n;
