import React, { Component } from 'react';

class Detail extends Component {
  state = {
    active: !!this.props.active,
  };
  toggleClass = () => this.setState({ active: !this.state.active });
  render() {
    const { title, description } = this.props.detail;
    return (
      <div className="detail-item">
        <p
          className={`detail ${this.state.active ? 'open' : 'hide'}`}
          onClick={() => this.toggleClass()}
        >
          {title}
        </p>
        <div className="detail-block">
          <p dangerouslySetInnerHTML={{ __html: description }}></p>
        </div>
      </div>
    );
  }
}
export default Detail;
