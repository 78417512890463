import React from 'react';
import Icon from '../../components/icon/icon.js';

const Block = ({ text }) => {
  return (
    <div className="block-group">
      <div className="block-empty"></div>
      <div className="block-text">
        {text.map((item, index) => (
          <p key={index}>{item}</p>
        ))}
        <Icon icon="rectangle100" iconClass="line" />
      </div>
    </div>
  );
};
export default Block;
